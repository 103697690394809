@import 'src/utils/utils';

.menu {

  &__desktop {
    @include zero;
    position: absolute;
    left: 0;
    z-index: 5;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 57px);
    padding: 16px;
    background-color: $Main-2;
    transition: transform 0.3s;
    transform: translateX(-100%);
    overflow-x: hidden;
    overflow-y: auto;

    @media(min-width: $lg) {
      position: initial;
      width: 300px;
      height: unset;
      max-height: calc((var(--vh, 1vh) * 100) - 30px - 30px);
      padding: 25px;
      border-radius: 20px;
      transition: unset;
      transform: translateX(0);
    }

    @media(min-width: $xl) {
      width: 400px;
    }

    &::-webkit-scrollbar {
      @media(min-width: $lg) {
        width: 10px;
      }
    }

    &::-webkit-scrollbar-track {
      @media(min-width: $lg) {
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: transparent;
      }
    }

    &::-webkit-scrollbar-thumb {
      @media(min-width: $lg) {
        height: 4px;
        border: 3px solid #ffffff;
        border-radius: 8px;
        background-color: #b0b0b0;
      }
    }
  }

  &__mobile {
    @include zero;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-left: 16px;
    border-bottom: 1px solid $Main-4;
    background-color: $Main-2;

    @media(min-width: $lg) {
      display: none;
    }
  }

  &__mobile-image {
    width: 104px;
    height: 32px;

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  &__navigation {
    @include zero;
    display: flex;
    gap: 20px;
  }

  &__button {
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    color: $Main-1;
    border: 0;
    background: 0;
    text-decoration: none;

    svg {
      display: block;
      width: 24px;
      height: 24px;
      stroke: currentColor;
    }

    p {
      @include zero;
      @include Text-18-reg;
      display: block;
      width: 24px;
      height: 24px;
      color: $Main-2;
      background-color: $Corporate-2;
      border-radius: 50%;
      line-height: 1.438rem;
      text-align: center;
    }
  }

  &__burger {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    color: inherit;
    background-color: transparent;
    transition: color 0.2s;
    transform-origin: 50% 50%;
    cursor: pointer;
    user-select: none;

    span {
      position: relative;
      display: block !important;
      width: 20px;
      height: 2px;
      background: linear-gradient(to right, currentcolor 75%, transparent 75% 100%);
      transition:
        width 0.2s,
        background-color 0.2s 0.2s;

      &:before {
        position: absolute;
        top: 7px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: currentcolor;
        transition:
          top 0.2s 0.2s,
          transform 0.2s;
        content: '';
      }

      &:after {
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 50%;
        height: 2px;
        background-color: currentcolor;
        transition:
          bottom 0.2s 0.2s,
          transform 0.2s;
        content: '';
      }
    }
  }

  &__section {
    @include zero;
    display: grid;

    &:not(:last-of-type) {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $Main-5;

      @media(min-width: $lg) {
        margin-bottom: 20px;
      }
    }
  }

  &__image {
    @include zero;
    display: block;
    width: 140px;
    height: 40px;
    margin-bottom: 20px;
    display: none;

    @media(min-width: $lg) {
      display: block;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__link {
    @include Text-14-reg;
    @include zero;
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $Main-1;
    text-decoration: none;
    transition: color 0.3s;
    column-gap: 10px;

    @media(min-width: $lg) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;

        svg {
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;
      stroke: currentcolor;
      flex-shrink: 0;
      transition: transform 0.3s;
      will-change: transform;
    }
  }

  &__list {

    &--active {

      .menu__list-inner {
        transition:
          visibility 0.2s ease-out 0.3s,
          opacity 0.2s ease-out 0.3s,
          max-height 0.3s ease-out;
        visibility: visible;
        opacity: 1;
      }

      .menu__list-toggle span:after {
        transform: rotate(45deg);
      }
  
      .menu__list-toggle span:before {
        transform: rotate(-45deg);
      }
    }

    &--red {

      .menu__list-header a {
        color: $Corporate-2;
      }
    }

  }

  &__list-header {
    @include Text-14-reg;
    @include zero;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
    gap: 10px;

    a {
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      column-gap: 10px;
      color: $Main-1;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        @media(min-width: $lg) {
          color: $Corporate-2;

          svg {
            transform: scale3d(1.1, 1.1, 1.1);
          }
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;
      margin-bottom: auto;
      stroke: currentcolor;
      flex-shrink: 0;
      transition:
        transform 0.3s,
        stroke 0.3s;
      will-change: transform;
    }
  }

  &__sub-header {
    @include zero;
    @include Text-14-reg;
    color: $t-40;
  }

  &__list-toggle {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    color: $Main-1;
    border: 0;
    background: 0;
    transition: color 0.3s;
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;

        span {
          transform: scale3d(1.2, 1.2, 1.2);
        }
      }
    }

    span {
      position: relative;
      display: inline-block;
      width: 12px;
      height: 1px;
      transition: transform 0.5s ease-out;
      vertical-align: bottom;
      background: 0 0;

      @media(min-width: $md) {
        height: 2px;
      }

      &:before,
      &:after {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transition: transform 0.3s;
        transform-origin: 50% 50%;
        content: '';

        @media(min-width: $md) {
          height: 2px;
        }
      }

      &:before {
        left: -4px;
        transform: rotate(45deg);

        @media(min-width: $md) {
          left: -4px;
        }
      }

      &:after {
        right: -4px;
        transform: rotate(-45deg);

        @media(min-width: $md) {
          right: -4px;
        }
      }
    }
  }

  &__list-inner {
    max-height: 0;
    transition:
      visibility 0.2s ease-in,
      opacity 0.2s ease-in,
      max-height 0.3s ease-in 0.2s;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
  }

  &__sub-list {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-left: 12px;
    padding-bottom: 10px;
    gap: 10px;
  }

  &__sub-link {
    @include zero;
    @include Text-14-reg;
    position: relative;
    color: $Main-1;
    text-decoration: none;
    padding-left: 22px;
    transition: 0.3s;

    &:before {
      position: absolute;
      top: 10px;
      left: 0;
      width: 12px;
      height: 1px;
      margin: auto;
      border-top: 1px dashed $Main-6;
      content: '';
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: calc(100% + 10px);
      margin: auto;
      border-left: 1px dashed $Main-6;
      content: '';
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    &:last-child {

      &:after {
        height: 10px;
      }
    }
  }

  &--active {

    .menu__desktop {
      transform: translateX(0);
    }

    .menu__line {
      background-color: $Corporate-2;
    }

    .menu__burger span {
      width: 24px;
      background: transparent;
      transition:
        width 0.2s 0.2s,
        background 0.2s;

      &:before {
        top: 0;
        width: 100%;
        transition:
          top 0.2s,
          width 0s 0.2s,
          transform 0.2s 0.2s;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        width: 100%;
        transition:
          bottom 0.2s,
          width 0s 0.2s,
          transform 0.2s 0.2s;
        transform: rotate(-45deg);
      }
    }
  }

  &__logo {
    @include zero;
    width: 174px;
    height: 50px;
    display: none;

    @media(min-width: $lg) {
      display: block;
    }

    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}