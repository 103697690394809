@mixin zero {
  padding: 0;
  margin: 0;
}

@mixin box {
  position: relative;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;

  @media(min-width: 1921px) {
    margin-left: auto;
    margin-right: auto;
  }
}

$md: 767px;
$lg: 991px;
$xl: 1280px;
$xxl: 1440px;

$openSans: 'Open Sans';
$openSansCondensed: 'Open Sans Condensed';
$verdana: 'Verdana';

$Corporate-1: rgba(166, 25, 46, 1);
$Corporate-2: rgba(203, 51, 59, 1);
$Corporate-3: rgba(118, 35, 47, 1);
$Corporate-4: rgba(126, 127, 116, 1);
$Corporate-5: rgba(238, 39, 55, 1);
$Corporate-6: rgba(195, 198, 168, 1);

$Alternative-1: rgba(255, 103, 31, 1);
$Alternative-2: rgba(149, 108, 88, 1);
$Alternative-3: rgba(39, 116, 174, 1);
$Alternative-4: rgba(73, 173, 99, 1);
$Alternative-5: rgba(238, 39, 55, 1);
$Alternative-6: rgba(55, 58, 54, 1);
$Alternative-7: rgba(100, 106, 137, 1);
$Alternative-8: rgba(152, 164, 174, 1);
$Alternative-9: rgba(252, 240, 240, 1);
$Alternative-10: rgba(239, 174, 118, 1);

$Main-1: rgba(17, 17, 17, 1);
$Main-2: rgba(255, 255, 255, 1);
$Main-3: rgba(250, 250, 250, 1);
$Main-4: rgba(241, 241, 241, 1);
$Main-5: rgba(218, 218, 218, 1);
$Main-6: rgba(176, 176, 176, 1);
$Dark-1: rgba(34, 34, 34, 1);
$Dark-2: rgba(56, 56, 56, 1);
$Dark-3: rgba(122, 122, 122, 1);
$Dark-4: rgba(21, 21, 21, 1);

$t-60: rgba(17, 17, 17, 0.6);
$t-40: rgba(17, 17, 17, 0.4);


// Shadows settings
@mixin Shadow-1 {
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.10);
}


// fonts settings
@mixin Head-60-bold {
  font-family: $openSansCondensed;
  font-size: 1.188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: 0.5px;

  @media(min-width: $lg) {
    font-size: 2.25rem;
    line-height: 2.375rem;
  }
}

@mixin Head-45-bold {
  font-family: $openSansCondensed;
  font-size: 2.813rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.125rem;
  letter-spacing: 0.5px;
  font-feature-settings: 'clig' off, 'liga' off;
}

@mixin Head-35-bold {
  font-family: $openSansCondensed;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;

  @media(min-width: $lg) {
    font-size: 2.188rem;
    line-height: 2.5rem;
  }
}

@mixin Head-25-bold {
  font-family: $openSansCondensed;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
  font-feature-settings: 'clig' off, 'liga' off;
}

@mixin Head-22-bold {
  font-family: $openSansCondensed;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
}

@mixin Text-18-bold {
  font-family: $verdana;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
}

@mixin Text-18-bold-underline {
  font-family: $verdana;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
  text-decoration-line: underline;
}

@mixin Text-18-reg {
  font-family: $verdana;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
}

@mixin Text-18-reg-underline {
  font-family: $verdana;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  text-decoration-line: underline;
}

@mixin Text-16-bold {
  font-family: $verdana;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;

  @media(min-width: $lg) {
    line-height: 1.563rem;
  }
}

@mixin Text-16-reg {
  font-family: $verdana;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;

  @media(min-width: $lg) {
    line-height: 1.563rem;
  }
}

@mixin Text-16-reg-underline {
  font-family: $verdana;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-decoration-line: underline;

  @media(min-width: $lg) {
    line-height: 1.563rem;
  }
}

@mixin Text-14-bold {
  font-family: $verdana;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}

@mixin Text-14-reg {
  font-family: $verdana;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

@mixin Text-14-reg-underline {
  font-family: $verdana;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  text-decoration-line: underline;
}

@mixin Text-13-bold {
  font-family: $verdana;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}

@mixin Text-13-reg {
  font-family: $verdana;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

@mixin Text-12-bold {
  font-family: $verdana;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
}

@mixin Text-12-reg {
  font-family: $verdana;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}

@mixin Text-12-reg-underline {
  font-family: $verdana;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  text-decoration-line: underline;
}

@mixin Text-11-bold-table {
  font-family: $verdana;
  font-size: 0.688rem;
  font-style: normal;
  font-weight: 700;
  line-height: 16rem;
  letter-spacing: 0.2px;
}

@mixin Text-10-reg {
  font-family: $verdana;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}